<template>
  <div class="views-layout-third-login">
    <a-spin class="loading" tip="加载中..." :spinning="loading" style="width: 100%"></a-spin>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true
    };
  },
  created() {
    if (!this.$route.query?.authToken) {
      this.api.confirm('请携带正确的身份信息', 0)
      this.loading = false
      return
    }
    this.setToken(this.$route.query.authToken)
    this.redirect()
  },
  methods: {
    setToken(token) {
      this.api.storage.setCookie("systemName", "UCS", { path: "/" });
      this.api.storage.setCookie("UCS-AUTHEN-TOKEN", token, {
        path: "/",
      });
      this.api.storage.setCookie("sso_sys_token", token, {
        path: "/",
      });
    },
    redirect() {
      let redirectUrl = this.$route.query?.redirectUrl || '/ucs/ems'; // 默认跳转费用
      location.replace(redirectUrl)
    },
  },
};
</script>

<style lang="less" scoped>
.views-layout-third-login {
  .loading {
    position: absolute;
    top: 40%;
  }
}
</style>